import React, { useRef } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import { rhythm, scale } from "../utils/typography"
import Image from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper'

import HomeIcon from '../../content/assets/home.svg'
import AboutIcon from '../../content/assets/about.svg'
import BlogIcon from '../../content/assets/notebook.svg'
import PrivacyIcon from '../../content/assets/privacy.svg'
import PersonIcon from '../../content/assets/login.svg'
import PriceIcon from '../../content/assets/price.svg'

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      light: '#53B5FF',
      main: '#007cff',
      dark: '#057BD4',
      contrastText: '#fff',
    },
    background: {
        paper: '#fff',
        main: '#fff',
        light: '#fafafa',
      },
    },
    overrides: {
        MuiPaper: {
            root: {
                background: 'linear-gradient(38deg, rgba(255,255,255,1) 35%, rgba(9,9,121,1) 35%, rgba(250,250,250,1) 35%)',
            }
        }
    }
  })

/*
#51255e = purple
#c8514b = red/orange
#f4cd62 = yellow

dark blue = #1c2b3d
real dark blue = #0f2034
light blue = #3c4b62
*/

const Menu = () => {
  return (
    <div>
           <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
      <div style={{cursor: 'pointer'}}>
      <div style={{textAlign: 'center'}}>
          <HomeIcon style={{fontSize: 36, width: 54, height: '1.5em'}}/>
          </div>            <div style={{    fontSize: '12px',
    textAlign: 'center',
    marginTop: -12,
    marginBottom: 14}}>HOME</div>
      </div>
      </Link>
      <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/about`}
        >
      <div style={{cursor: 'pointer'}}>
      <div style={{textAlign: 'center'}}>
          <AboutIcon style={{fontSize: 36, width: 54, height: '1.5em'}}/>
          </div>            <div style={{    fontSize: '12px',
    textAlign: 'center',
    marginTop: -12,
    marginBottom: 14}}>ABOUT</div>
      </div>
          </Link>
          <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/blog`}
        >
      <div style={{cursor: 'pointer'}}>
      <div style={{textAlign: 'center'}}>
          <BlogIcon style={{fontSize: 36, width: 54, height: '1.5em'}}/>
          </div>        <div style={{    fontSize: '12px',
    textAlign: 'center',
    marginTop: -12,
    marginBottom: 14}}>BLOG</div>
      </div>
</Link>
<Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/pricing`}
        >
      <div style={{cursor: 'pointer'}}>
        <div style={{textAlign: 'center'}}>
          <PriceIcon style={{fontSize: 36, height: '1.5em'}}/>
          </div>
        <div style={{    fontSize: '12px',
    textAlign: 'center',
    marginTop: -12,
    marginBottom: 14}}>PRICING</div>
      </div>
</Link>
<Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/privacy`}
        >
      <div style={{cursor: 'pointer'}}>
      <div style={{textAlign: 'center'}}>
          <PrivacyIcon style={{fontSize: 36, width: 54, height: '1.5em'}}/>
          </div>            <div style={{    fontSize: '12px',
    textAlign: 'center',
    marginTop: -12,
    marginBottom: 14}}>PRIVACY</div>
      </div>
</Link>
<Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/signup`}
        >
      <div style={{cursor: 'pointer'}}>
      <div style={{textAlign: 'center'}}>
          <PersonIcon style={{fontSize: 36, width: 54, height: '1.5em'}}/>
          </div>            <div style={{    fontSize: '12px',
    textAlign: 'center',
    marginTop: -12,
    marginBottom: 14}}>SIGNUP</div>
      </div>
      </Link>

    </div>
  )
}


const drawerWidth = 75;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundColor: 'white !important',
    color: 'black'

  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  main: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 60,
    },
  },
  title: {
    [theme.breakpoints.down('xs')]: {

      fontSize: '50px !important',
      lineHeight: 'initial !important'
    }
  }
}));


const Layout = ({ location, title, children }) => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      hero_image: file(absolutePath: { regex: "/09.png/" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 3000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo: file(absolutePath: { regex: "/laptop_logo.png/" }) {
        childImageSharp {
          fixed(width: 60, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  // const posts = data.allMarkdownRemark.edges
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const container = useRef(null)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <h1
        style={{
          ...scale(1.5),
          marginBottom: rhythm(1.5),
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          <span style={{color: '#51255e'}}>D</span>ev <span style={{color: '#c8514b'}}>I</span>nternship <span style={{color: '#f4cd62'}}>D</span>otcom
        </Link>
      </h1>
    )
  } else {
    header = (
      <h3
        style={{
          fontFamily: `Montserrat, sans-serif`,
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          <span style={{color: '#51255e'}}>D</span>ev <span style={{color: '#c8514b'}}>I</span>nternship <span style={{color: '#f4cd62'}}>D</span>otcom
        </Link>
      </h3>
    )
  }

  
  return (
    <MuiThemeProvider theme={theme}>

    <BackgroundImage Tag="section"	
    fluid={data.hero_image.childImageSharp.fluid}	
    backgroundColor={`#c5cbd1`}
    fadeIn={'soft'}	
    backgroundSize={"repeat"}	
    style={{height: '100vh'}}	
>
    <div ref={container} style={{height: '100vh', width: '100vw', overflow: 'scroll'}}>

<Hidden smUp implementation="css">


<AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end'}}>
          <Image
        fixed={data.logo.childImageSharp.fixed}
      />  
      </div>   
        </Toolbar>
      </AppBar>
      </Hidden>
  <div style={{display: 'flex'}}>
    <div className={classes.drawer} style={{flexShrink: 1}}>
<Hidden smUp implementation="css">
          <Drawer
            container={container.current}
            variant="temporary"
            anchor={'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
    <div style={{height: 60}}></div>
            <Menu/>          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            variant="permanent"
            PaperProps={{elevation: 3}}
            elevation={3}
            open
          >
            <div style={{width: 75}}>
              <div style={{height: 75, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Image
        fixed={data.logo.childImageSharp.fixed}
      />       
      </div> 
            </div>
            <Menu/>
       </Drawer>
        </Hidden>
        </div>
    <div className={classes.main} style={{padding: 8, flex: 1}}>
      <Paper elevation={3} style={{margin: 16}}>
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <header>{header}</header>
      <main>{children}</main>
      {/* <footer>
        © {new Date().getFullYear()}
      </footer> */}
    </div>
         </Paper>
         </div>
       </div>
       </div>
       </BackgroundImage>
       </MuiThemeProvider>
  )
}

export default Layout